<template>
    <div v-if="isLoggedIn" class="container page">


        <!--
    **************************************************************************************************************************************************
                                                                N E W     A D H O C
    **************************************************************************************************************************************************
    -->


        <b-alert v-if="!isManager" show>Only a manager may access this module.</b-alert>


        <section v-if="displayPart==0 && isManager" class="card card-default">
            <div class="card-header">
                <h5>Adhoc Recognition</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-6"><strong>Recipient:</strong></div>
                    <div class="col-sm-6 text-right"></div>
                </div>
                <div v-for="r in selectedMems" :key="r._rowNum" class="row">
                    <template v-if="r.isEntireDivision">
                        <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                        <div class="col" style="color:#9f34a0">Entire division: {{ r.division }}</div>
                    </template>
                    <template v-else>
                        <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                        <div class="col-2">{{ r.memberNumber }}</div>
                        <div class="col">{{ r.firstname + ' ' + r.surname }}</div>
                        <div class="col">{{ r.division }}</div>
                        <div class="col-1">{{ r.points}}</div>
                    </template>
                </div>
                <div v-if="selectedMems.length===0" class="text-danger">Please select a recipient.</div>
                <div class="mt-1">
                    <b-button type="button" pill @click="$bvModal.show('modalChooseNominees')" variant="primary" size="sm" class="mt-2">{{selectedMems.length===0 ? 'Select' : 'Change'}}  Recipient</b-button>
                    <!--<a href="#" @click.prevent="$bvModal.show('modalChooseNominees')">Select Nominees</a>-->
                </div>

                <hr />
                <b-form @submit.prevent="onSubmitAdhoc" novalidate>
                    <b-form-group id="groupReason" label-for="selectreason" label="Value:" :invalid-feedback="fAdhoc.errors.reason" description="">
                        <b-form-select id="selectreason" v-model="fAdhoc.form.reason" :state="fAdhoc.state.reason" :options="valueOptions"></b-form-select>
                    </b-form-group>

                    <b-form-group id="groupPoints" label-for="selectPoints" label="Points Amount:" :invalid-feedback="fAdhoc.errors.points" description="" :state="fAdhoc.state.points">
                        <b-form-input id="selectPoints" v-model="fAdhoc.form.points" :state="fAdhoc.state.points" placeholder="" maxlength="5" />
                        <!--<b-form-select id="selectPoints" v-model="fAdhoc.form.points" :state="fAdhoc.state.points" :options="pointsOptions"></b-form-select>-->
                    </b-form-group>

                    <b-form-group id="groupDescrAppend" label-for="selectdescrAppend" label="Cost Centre:" :invalid-feedback="fAdhoc.errors.descrAppend" description="">
                        <b-form-input id="selectdescrAppend" v-model="fAdhoc.form.descrAppend" :state="fAdhoc.state.descrAppend"></b-form-input>
                    </b-form-group>

                    <b-form-group id="groupSendBy" label-for="sendBy" description="" :invalid-feedback="fAdhoc.errors.sendBy" :state="fAdhoc.state.sendBy" class="mt-4">
                        <b-form-radio-group id="radiogroupSendBy" v-model="fAdhoc.form.sendBy" name="sendBy" :state="fAdhoc.state.sendBy">
                            <div class="row">
                                <div class="col-sm-3 col-lg-2">
                                    <b-form-radio value="email">Email</b-form-radio>
                                </div>
                                <div class="col-sm-3 col-lg-2">
                                    <b-form-radio value="sms">SMS</b-form-radio>
                                </div>
                            </div>
                        </b-form-radio-group>
                    </b-form-group>

                    <b-form-group id="groupAdhocMessage" label-for="txtAdhocMessage" label="Message to Recipients" :invalid-feedback="fAdhoc.errors.message" :description="(isSendBySMS?150:500)-fAdhoc.form.message.length + ' characters remaining'">
                        <b-form-textarea id="txtAdhocMessage" :disabled="fAdhoc.form.sendBy===null" v-model="fAdhoc.form.message" :state="fAdhoc.state.message" :placeholder="isSendBySMS ? 'Short SMS message to recipients up to 150 characters' : 'Short email message to recipients up to 500 characters'" rows="3" max-rows="5" :maxlength="(isSendBySMS?150:500)" />
                    </b-form-group>



                    <hr />

                    <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                </b-form>
            </div>
        </section>

        <section v-if="displayPart==1" class="card card-default">
            <div class="card-header">
                <h5>Adhoc Recognition</h5>
            </div>
            <div class="card-body">
                <b-alert variant="success" show>
                    <h4 class="alert-heading">Adhoc Recognition</h4>
                    <p class="preline">
                        {{sendStatus}}
                    </p>
                    <hr />
                    <p class="preline" v-if="sendErrors != ''">
                        <b>Errors:</b><br />
                        {{sendErrors}}
                    </p>

                    <hr />
                    <p class="preline" v-if="sendSuccess != ''">
                        <b>Successful:</b><br />
                        {{sendSuccess}}
                    </p>
                </b-alert>
            </div>
            <div class="card-footer">
                <div class="text-right"><button type="button" @click="displayPart=0" class="btn btn-primary">Send Another</button></div>
            </div>
        </section>



        <!--
    **************************************************************************************************************************************************
                                                                M O D A L S
    **************************************************************************************************************************************************
    -->
        <!-- Modal - Nominees -->
        <b-modal id="modalChooseNominees" title="ADHOC RECOGNITION REWARD" hide-footer size="xl" no-close-on-backdrop>
            <div>
                <div>Enter <b>Member-Number, Amount</b>  (Enter one member number per line. Maximum 500)</div>
                <b-form-textarea 
                                 v-model="memberNums"
                                 placeholder="Enter member-number, amount - one per line. Maximum 500."
                                 rows="15">
                </b-form-textarea>
            </div>
            <div>
                <b-form-checkbox v-model="ignoreMissingMemberNums">
                    Ignore missing member numbers
                </b-form-checkbox>
            </div>

            <div class="text-right mt-3"><b-button type="submit" variant="primary" @click="processMemberNums()">Submit</b-button></div>
            <hr />

            
        </b-modal>
    </div>

</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';
    import axios from 'axios'
    import sjData from '@/sjUtilData'
    //import MemberSelect from '@/components/MemberSelect';


    export default {
        name: 'AdhocBulk',
        components: {},
        data() {
            return {
                displayPart: 0,

                fAdhoc: {
                    form: { what: 'adhocbulk', reason: null, points: '', message: '', sendBy: null, descrAppend: null, recipients: [] }
                },

                selectedMems: [],

                roles: { ca: false, cc: false, idea: false, inv: false },

                reportTitle: '',

                memberNums: '',
                missingMemberNums: '',
                ignoreMissingMemberNums: false,


                sendAllMems: [],
                sendPos: 0,
                sendStatus: '',
                sendErrors: '',
                sendSuccess: '',
                sendSuccessCount: 0,
                sendErrorCount: 0,

				//customPoints: ''


            }
        },
        computed: {
			//pointsOptions() {
			//	const pointsList = [5, 10, 20, 25, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 1000, 2000, 3000, 4000, 5000];
			//	let p = [{ text: '--- Select Amount to Award---', value: null, disabled: true }];
			//	pointsList.forEach(n => p.push({ text: n + '  e-points', value: n }));
			//	return p;
			//},
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
            isManager() {
                return this.$store.state.memberStore.member.isManager
            },
            countryList() {
                if (this.isCountryFixed) { //only show the default cluster.
                    return this.$store.state.countryList.filter(country => country.countryCode === this.defaultCountryCode);
                } else {
                    return [{ countryCode: null, country: '-- Please select a Cluster--', disabled: true }]
                        .concat(this.$store.state.countryList);
                }
            },
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
            isSendBySMS() {
                return this.fAdhoc.form.sendBy === 'sms';
            },
			valueOptions() {
				//const santamOptions = [
				//	{ html: '<b>--- Santam Options ---</b>', value: null, disabled: true },
				//	{ text: 'EXCELLENCE', value: 'EXCELLENCE' },
				//	{ text: 'HUMANITY', value: 'HUMANITY' },
				//	{ text: 'INTEGRITY', value: 'INTEGRITY' },
				//	{ text: 'INNOVATION', value: 'INNOVATION' },
				//	{ text: 'PASSION', value: 'PASSION' },
				//	{ html: '<b>--- Group Options ---</b>', disabled: true }
				//];

                const groupOptions = [
                    { text: 'Care', value: 'Care' },
                    { text: 'Collaboration', value: 'Collaboration' },
                    { text: 'Integrity', value: 'Integrity' },
                    { text: 'Innovation', value: 'Innovation' },
                ];
                //return this.memberClusterCode === 'stm' ? [...santamOptions, ...groupOptions] : [{ text: '--- Select an Option ---', value: null }, ...groupOptions];
                return [{ text: '--- Select an Option ---', value: null }, ...groupOptions];
			},
        },
        created() {
            //formSetState must be called on creation, to ensure all fields are setup
            this.formSetState(this.fAdhoc, true, true) //formObj, resetState, resetForm

            this.$store
                .dispatch('getDivisionList', {}) //To retrieve Country and Division lists
                .then(() => {
                    this.displayPart = 0;

                })
                .catch((error) => {
                    this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error)));
                });


        },
        mounted() {

        },
        methods: {

            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },

            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },

            processMemberNums() {
                const lines = this.memberNums.split(/\r?\n/);


                //console.log('lines', lines);
                const arLines = lines.map((e) => e.split(/[|:,;]/));
                //console.log('arLines', arLines);


                const arMems = arLines.map((e) => e[0].trim());
                if (new Set(arMems).size !== arMems.length) {
                    this.msgBox('Duplicate Member Numbers found', 'No Duplicate Member Numbers Allowed');
                    return;
                }

                var memNums = '';


                arLines.forEach((e) => memNums += e[0] + '\n');
                //console.log('memNums', memNums);

                return axios
                    .post(
                        '/api/site/GetMemberIdsFromMemberNums',
                        { memberNums: memNums }
                    )
                    .then(({ data }) => {
                        //this.currentMemberList = new sjData(data.memberList).data;
						let memberNotFound = new sjData(data.memberNotFound).data;
                        if (memberNotFound.length != 0) {
                            //debugger;
                            let msg = '';
                            memberNotFound.forEach(rec => msg += rec.missingMemberNumber + '<br />');
                            if (!this.ignoreMissingMemberNums) {
                                const h = this.$createElement;
                                const msgVNode = h('div', { domProps: { innerHTML: msg } })
                                this.msgBox(msgVNode, 'The following members were not found/registered');
                                return;
                            }
						}
                        let selMems = new sjData(data.memberList).data;
                        selMems.forEach((e) => {
                            let amtRec = arLines.find((l) => l[0] == e.memberNumber);
                            if (typeof amtRec === 'undefined') {
                                this.msgBox('Could not find MemberNumber: ' + e.memberNumber);
                                return;
                            }
                            if (Number.isNaN(Number.parseInt(amtRec[1]))) {
                                this.msgBox('Invalid amount on Member: ' + e.memberNumber);
                                return;
                            }
                            e.points = Number.parseInt(amtRec[1]);
                        });

                        this.selectedMems = selMems;// new sjData(data.memberList).data;

						this.$bvModal.hide('modalChooseNominees');

                    })
                    .catch((error) => {
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                    })
                    ;
            },




            onSubmitAdhoc() {
                if (this.displayPart != 0) {
                    this.msgBox('Unexpected (displayPart=0)', 'Error');
                    return;
                }
                if (this.selectedMems.length === 0) {
                    this.msgBox('Please select at least one recipient.', 'Recipients required');
                    return;
                }

                this.sendAllMems = this.selectedMems.map(item => item.isEntireDivision ? 'div:' + item.entireDivisionCode : item.memberId);
                this.sendPos = 0;
                this.displayPart = 1;

                this.sendErrors = "";
                this.sendSuccess = "";
                this.sendErrorCount = 0;
                this.sendSuccessCount=0;;


                this.sendBatch();
            },

            sendBatch() {
                //https://dev.to/burkeholland/asyncawait-and-the-foreach-pit-of-despair-2267
                let allMems = this.sendAllMems;
                if (this.sendPos >= allMems.length) {
                    this.sendStatus = "Completed " + allMems.length + "";
                    this.selectedMems = [];
                    this.formSetState(this.fAdhoc, true, true);
                    this.$store.dispatch('memberStore/getMemberData');

                    this.msgBox('Completed sending adhoc', 'Send Adhoc');
                    return;
                }




                let pos = this.sendPos;
                const size = 4;
                let theMems = allMems.slice(pos, pos + size);

                this.formSetState(this.fAdhoc, true);//formObj, resetState, resetForm
                this.fAdhoc.form.recipients = theMems;


                let who = (this.sendPos + 1) + " - " + (this.sendPos + theMems.length) + " : ";
                for (var i = 0; i < theMems.length; i++) {
                    who += (i == 0 ? "" : ", ") + theMems[i].sjRemoveIntHash();
                }


                this.sendStatus = "Sending: " + (this.sendPos + 1) + " - " + (this.sendPos + theMems.length) + " of " + allMems.length + "\r\n\r\n" + who;

                this.sendPos += size;
                //this.displayPart = 1;

                //               if (this.fAdhoc.form.points == 'custom') {
                //                   this.fAdhoc.form.points = this.customPoints;
                //}
                return axios
                    .post(
                        '/api/reward/doreward',
                        this.fAdhoc.form
                    )
                    .then(({ data }) => {
                        console.log(data);
                        this.sendSuccess += who + "\r\n";
                        this.sendSuccessCount += size;
                        this.sendBatch();
                        //this.msgBox('Successfully submitted nomination');
                    })
                    .catch((error) => {
                        console.error(error);

                        if (error && error.response && error.response.data) {
                            this.sendErrors += "Error sending: " + who + " \r\n\r\n-\r\n " + (error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data))) + "\r\n";

                            this.sendErrorCount += size;
                            if (error.response.data.formErrors) {
                                this.fAdhoc.errors = error.response.data.formErrors;
                                this.formSetState(this.fAdhoc);
                                this.displayPart = 0;
                                this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                            } else {
                                setTimeout(() => this.sendBatch(), 200);
                            }

                        } else {
                            this.sendErrorCount += size;
                            this.sendErrors += "Error sending: " + who + "\r\n\r\n " + JSON.stringify(error) + "\r\n";

                        }
                    })
                    ;
            },

            sendBatchoOLD() {
                let allMems = this.sendAllMems;
                if (this.sendPos >= allMems.length) {
                    this.sendStatus = "Completed " + allMems.length + "";
                    this.selectedMems = [];
                    this.formSetState(this.fAdhoc, true, true);
                    this.$store.dispatch('memberStore/getMemberData');

                    this.msgBox('Completed sending adhoc', 'Send Adhoc');
                    return;
                }

                let pos = this.sendPos;
                const size = 4;
                let theMems = allMems.slice(pos, pos + size);

                this.formSetState(this.fAdhoc, true);//formObj, resetState, resetForm
                this.fAdhoc.form.recipients = theMems;


                let who = (this.sendPos + 1) + " - " + (this.sendPos + theMems.length) + " : ";
                for (var i = 0; i < theMems.length; i++) {
                    who += (i == 0 ? "" : ", ") + theMems[i].sjRemoveIntHash();
                }

                
                this.sendStatus = "Sending: " + (this.sendPos + 1) + " - " + (this.sendPos + theMems.length) + " of " + allMems.length + "\r\n\r\n" + who;

                this.sendPos += size;
                //this.displayPart = 1;
               
     //               if (this.fAdhoc.form.points == 'custom') {
     //                   this.fAdhoc.form.points = this.customPoints;
					//}
                return axios
                    .post(
                        '/api/reward/doreward',
                        this.fAdhoc.form
                    )
                    .then(({ data }) => {
                        console.log(data);
                        this.sendSuccess += who + "\r\n";
                        this.sendSuccessCount+=size;
                        this.sendBatch();
                        //this.msgBox('Successfully submitted nomination');
                    })
                    .catch((error) => {
                        console.error(error);
                        
                        if (error && error.response && error.response.data) {
                            this.sendErrors += "Error sending: " + who + " \r\n\r\n-\r\n " + (error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data))) + "\r\n";
                        
                            this.sendErrorCount +=size;
                            if (error.response.data.formErrors) {
                                this.fAdhoc.errors = error.response.data.formErrors;
                                this.formSetState(this.fAdhoc);
                                this.displayPart = 0;
                                this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                            } else {
                                setTimeout(() => this.sendBatch(), 200);
                            }

                        } else {
                            this.sendErrorCount +=size;
                            this.sendErrors += "Error sending: " + who + "\r\n\r\n " + JSON.stringify(error) + "\r\n";

                        }
                    })
                    ;
            },

        }




    }
</script>
